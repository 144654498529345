#error {
  animation: fadeIn 1s;
}

/* Path: src/Main.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
    background-color: #263238;
    width: 16px;
    height: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: #263238;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: #263238;
    border-radius: 16px;
    border: 4px solid #263238;
}

*::-webkit-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #455A64;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: #455A64;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display:none;
}
